import { DropdownItem } from '~/shared/components';
import Close from '$icons/close.svg';
import { useTranslation } from '$shared/utils/translation';

import {
    StyledSelectedFilters,
    StyledSelectedFilterButton,
    StyledSelectedClearButton,
} from './styled';
import { TranslationKey } from '~/lib/data-contract';

export interface SelectedFacetDropdown extends DropdownItem {
    attribute: string;
}
export interface SelectedFiltersProps {
    filters: SelectedFacetDropdown[];
    onClick?: (item: SelectedFacetDropdown) => void;
    onClearAll?: () => void;
}

export const SelectedFilters = ({ filters, onClick, onClearAll }: SelectedFiltersProps) => {
    const { translate } = useTranslation();
    const getFacetButtonLabel = (facet: SelectedFacetDropdown) => {
        if (facet.attribute === 'WebGeneralTags') {
            return translate(
                `Kompan.WebGeneralTags.${facet.value}` as TranslationKey,
                facet.value as string,
            );
        }

        return facet.label;
    };
    return (
        <StyledSelectedFilters>
            {filters.map((facet, index) => (
                <StyledSelectedFilterButton
                    key={`${facet.label}_${index}`}
                    variant="Transparent"
                    type="button"
                    size="Small"
                    disableHoverAnimation={true}
                    onClick={() => {
                        typeof onClick != 'undefined' && onClick(facet);
                    }}
                >
                    <Close aria-hidden="true" />
                    {getFacetButtonLabel(facet)}
                </StyledSelectedFilterButton>
            ))}
            {onClearAll && filters.length > 0 && (
                <StyledSelectedClearButton
                    key="DropdownFiltersBarClearAll"
                    variant="Ghost"
                    size="Small"
                    type="button"
                    disableHoverAnimation={true}
                    onClick={onClearAll}
                >
                    {translate('Kompan.Commerce.FiltersClearAll')}
                </StyledSelectedClearButton>
            )}
        </StyledSelectedFilters>
    );
};
