import { TranslationKey } from '~/lib/data-contract';
import { Frame } from '~/shared/utils';

type RangeOptionsProps = {
    facetKey?: string;
    frame: Frame;
    translate: (key: TranslationKey, fallback?: string) => string;
};

type RangeOptions = {
    maxValueRange: number;
    minValueRange: number;
    step: number;
    prependMin: undefined | React.ReactNode;
    prependMax: undefined | React.ReactNode;
    appendMin: undefined | React.ReactNode;
    appendMax: undefined | React.ReactNode;
};

const steps = {
    EUR: 100,
    GBP: 100,
    CHF: 100,
    CAD: 100,
    AUD: 100,
    USD: 100,
};
const maxValueRanges = {
    EUR: 100000,
    GBP: 100000,
    CHF: 100000,
    CAD: 100000,
    AUD: 100000,
    USD: 100000,
};

const defaultOptions: RangeOptions = {
    maxValueRange: 100,
    minValueRange: 1,
    step: 10,
    prependMin: undefined,
    prependMax: undefined,
    appendMin: undefined,
    appendMax: undefined,
};

export function getCurrencySymbol(frame: Frame): string | undefined {
    const locale = frame?.culture;
    const currencySymbol = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: frame?.currency,
    })
        .formatToParts(0)
        .find((part) => part.type === 'currency')?.value;
    return currencySymbol;
}

function getMaxValueRange(frame: Frame): number {
    return maxValueRanges[frame?.currency as keyof typeof maxValueRanges] || 100000;
}

function getPriceStepValue(frame: Frame): number {
    return steps[frame?.currency as keyof typeof steps] || 1000;
}

function getMetricUnit(frame: Frame): string {
    return frame.market?.market == 'US' ? 'in' : 'cm';
}

const optionsMap: Record<
    string,
    (frame: Frame, translate: (key: TranslationKey, fallback?: string) => string) => RangeOptions
> = {
    ListPrice: (frame) => {
        const currencySymbol = getCurrencySymbol(frame);

        return {
            ...defaultOptions,
            maxValueRange: getMaxValueRange(frame),
            step: getPriceStepValue(frame),
            prependMin: currencySymbol,
            prependMax: currencySymbol,
        };
    },
    MaxFallHeight: (frame) => {
        const append = getMetricUnit(frame);

        return {
            ...defaultOptions,
            minValueRange: 0,
            maxValueRange: append === 'in' ? 80 : 200,
            appendMin: append,
            appendMax: append,
        };
    },
    AgeGroup: (_, translate) => {
        return {
            ...defaultOptions,
            maxValueRange: 14,
            minValueRange: 0,
            step: 1,
            appendMin: translate(`Kompan.Commerce.FiltersAgeGroupLabelYears`),
            appendMax: translate(`Kompan.Commerce.FiltersAgeGroupLabelYears`),
        };
    },
    UserCapacity: (_, translate) => {
        return {
            ...defaultOptions,
            minValueRange: 0,
            appendMin: translate(`Kompan.Commerce.FiltersUserCapacityLabelUsers`),
            appendMax: translate(`Kompan.Commerce.FiltersUserCapacityLabelUsers`),
        };
    },
    // ADD more facetKey maps where is necessary.
};

export default function getRangeOptions({ facetKey, frame, translate }: RangeOptionsProps) {
    if (facetKey && optionsMap[facetKey]) {
        return optionsMap[facetKey](frame, translate);
    } else {
        return defaultOptions;
    }
}
